import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import CreateReport from "./CreateReport";
import CreateClientAccessReport from "./CreateClientAccessReport";
import { getReport } from "../../../../crud/analytics.crud";
import Loader from "../../../../components/Loader";

const CreateReportLoader = () => {
    const { id } = useParams();
    const [reportType, setReportType] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.includes("new_report")) {
            //TODO: get report type by type id, or get from request
            if (id === '16') {
                setReportType("client_access");
            }
            setLoading(false);
        } else {
            getReport(id).then((res) => {
                const result = res.data.data;
                setReportType(result.type.key);
                setLoading(false);
            });
        }
    }, [id, location.pathname]);

    if (loading) {
        return <Loader visible={loading} />;
    }

    // TODO: refactor other report types to separate components
    if (reportType === "client_access") {
        return <CreateClientAccessReport />;
    }

    // default for other report types
    return <CreateReport />;
};

export default CreateReportLoader;
