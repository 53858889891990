import React, { useState, useEffect, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DropdownComponent } from "./DropdownComponent";
import { connect, useSelector } from "react-redux";
import "./create-report.scss";
import {
  getGroup,
  getAllUsers,
  getAllGroup,
} from "../../../../crud/info.crud";
import {
  runReport,
  saveReport,
  exportReport,
  getReport,
  getTypes,
  showTypes,
  showReport,
} from "../../../../crud/analytics.crud";
import { fetchForms } from "../../../../crud/forms";
import { Details } from "./Details/Details";
import { SaveModal } from "./SaveModal/SaveModal";
import { DropdownMultiselect } from "./DropdownMultiselect";
import moment from "moment";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { getUsersV3 } from "../../../../crud/info.crud";

const CreateReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let role = useSelector((store) => store.auth.user.roles);
  let currentUser = useSelector((store) => store.auth.user);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({});
  const [prevData, setPrevData] = useState({});
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [groupsVis, setGroupsVis] = useState(false);
  const [usersVis, setUsersVis] = useState(false);
  const [isReportDetails, setIsReportDetails] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 20,
    to: 0,
    total: 0,
    sort: "change_percent",
    dir: "desc",
  });

  const [sort, setSort] = useState({
    sort: "change_percent",
    dir: "desc",
  });
  const [loader, setLoader] = useState(false);
  const [loaderSub, setLoaderSub] = useState(false);
  const [loaderUser, setLoaderUser] = useState(false);
  const [report, setReport] = useState([]);
  const [reportObj, setReportObj] = useState();
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState([]);
  const [reportType, setReportType] = useState("");
  const [isNewReport, setIsNewReport] = useState(true);

  useEffect(() => {
    const array = data?.groups?.length ? data.groups : [];

    FetchUsers(array.map((elem) => elem.id));
    setData((prev) => ({
        ...prev,
        users: [],
        }));
  }, [data.groups, name]);

  const FetchUsers = (selectedGroup) => {
    getUsersV3({ groups: selectedGroup || null, limit: 500 })
      .then((res) => {
        console.log("res", res);
        setUsers(
          res.data.list.map((elem) => ({
            ...elem,
            name: elem.first_name + " " + elem.last_name,
            title: elem.first_name + " " + elem.last_name,
          }))
        );
        setUsersVis(true);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    header.forEach((elem) => {
      if (elem.default_sort) {
        setTableMeta({
          current_page: 1,
          from: 1,
          last_page: 1,
          per_page: 20,
          to: 0,
          total: 0,
          sort: elem.key,
          dir: "desc",
        });
      }
    });
  }, [reportType, header]);

  useEffect(() => {
    if (location) {
      const isnewReport = location.pathname.includes("new_report");
      setIsNewReport(isnewReport);
      const id = location.pathname.split("/")[3];
      if (isnewReport) {
        showTypes(id).then((res) => {
          setReportObj(res.data.data);
          setName(res.data.data.name);
          setTitle(res.data.data.name);
          setHeader(res.data.data.fields);
        });
        setReportType(id);
      } else {
        showReport(id).then((res) => {
          const result = res.data.data;
          setReport(result.type.filters);
          setName(result.type.name);
          setTitle(result.name);
          setHeader(result.type.fields);
          setReportType(result.type.id);
            const obj = {
                groups: groups.filter((item) => result.groups.includes(item.id)),
                users: users.filter((item) => result.users.includes(item.id)),
            };
            setData(obj);
            setPrevData(obj);
          setIsReportDetails(true);
        });
      }
    }
  }, [location, groups.length, users.length]);

  useEffect(() => {
    if (!reportObj || !name) return;
    const data = reportObj.filters;
    const array = [];
    for (let key in data) {
      array.push(data[key]);
    }
    setReport(array);
  }, [reportObj]);

  useEffect(() => {
    setLoaderSub(true);
    getAllGroup()
      .then((res) => {
        setLoaderSub(false);
        setGroupsVis(true);
        let arr = res.data.data.map((elem) => ({ ...elem, title: elem.name }));
        setGroups(arr);
      })
      .catch((err) => {
        setLoaderSub(false);
      });
  }, [name]);

  const validate = () => {
    let tempError = {};
    setErrors(tempError);
    return JSON.stringify(tempError) === "{}";
  };

  useEffect(() => {
    if (isReportDetails) {
        if (groupsVis && usersVis) {
          handleRun(true);
        }
    }
  }, [
    isReportDetails,
    isNewReport,
    groupsVis,
    usersVis,
    tableMeta,
  ]);

  const handleRun = (noRewrite) => {
    if (loader) return;
    let prev = {};
    if (noRewrite) {
      prev = { ...prevData };
    } else {
      prev = { ...data };
    }
    if (!validate()) {
      return;
    } else {
      setErrors({});
      setLoader(true);

      let formData = {};

      let groups = [];
      prev?.groups?.length &&
        prev.groups.forEach((elem) => {
          if (!groups.find((item) => item === (elem.id || elem))) {
            if (elem.id) {
              groups.push(elem.id);
            } else {
              groups.push(elem);
            }
          }
        });

      let users = [];

        prev?.users?.length &&
          prev.users.forEach((elem) => {
            if (elem?.id) {
              users.push(elem.id);
            } else {
              users.push(elem);
            }
          });

        formData = {
          groups: groups,
          users: users,
          type_id: reportType,
        };

      runReport(formData, tableMeta)
        .then((res) => {
          setLoader(false);

          if (!noRewrite) {
            setPrevData(data);
          }
          setTableData(
            res.data.data.map((item) => ({
              ...item,
              change_percent: item.change_percent
                ? parseFloat(item.change_percent).toFixed(2)
                : null,
            }))
          );
          if (
            JSON.stringify(tableMeta) !==
            JSON.stringify({ ...tableMeta, ...res.data.meta })
          ) {
            setTableMeta({ ...tableMeta, ...res.data.meta });
          }
          if (res.data?.meta?.last_page < res.data?.meta?.current_page) {
            setTableMeta({ current_page: res.data.meta.last_page });
          }
          if (!res.data?.meta)
            tableMeta.total && setTableMeta((prev) => ({ ...prev, total: 0 }));
          setIsReportDetails(true);
        })
        .catch(({ response }) => {
          setLoader(false);
          if (response && response.data && response.data.errors) {
            const er = Object.keys(response.data.errors);
            let err = {};
            er.forEach((key) => {
              let keys = key.split(".");
              if (keys[0] === "report" && keys[1]) {
                err[keys[1]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              } else {
                err[keys[0]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              }
            });
            setErrors(err);
          }
        });
    }
  };

  const handleExport = () => {
    if (!validate()) {
      return;
    } else {
      setErrors({});
      setLoader(true);
      let formData = {};

      let groups = [];
      prevData?.groups?.length &&
        prevData.groups.forEach((elem) => {
          if (!groups.find((item) => item === (elem.id || elem))) {
            if (elem.id) {
              groups.push(elem.id);
            } else {
              groups.push(elem);
            }
          }
        });

        let users = [];
        prevData?.users?.length &&
          prevData.users.forEach((elem) => {
            if (elem?.id) {
              users.push(elem.id);
            } else {
              users.push(elem);
            }
          });

        formData = {
          groups: groups,
          users: users,
          type_id: reportType,
        };

      exportReport(formData, tableMeta)
        .then((response) => {
            setLoader(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
                link.setAttribute(
                "download",
                `Analytics-${name}-${moment().format("YYYY.MM.DD")}.csv`
                );

            link.click();
        })
        .catch(({ response }) => {
          setLoader(false);
          if (response && response.data && response.data.errors) {
            const er = Object.keys(response.data.errors);
            let err = {};
            er.forEach((key) => {
              let keys = key.split(".");
              if (keys[0] === "report" && keys[1]) {
                err[keys[1]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              } else {
                err[keys[0]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              }
            });
            setErrors(err);
          }
        });
    }
  };

  const onOpenSaveModal = () => {
    if (!validate()) {
      return;
    } else {
      setIsSaveModal(true);
    }
  };

  const [redirect, setRedirect] = useState(null);

  const handleSave = () => {
    let formData = {};

    let groups = [];
    data?.groups?.length &&
      data.groups.forEach((elem) => {
        if (!groups.find((item) => item === (elem.id || elem))) {
          if (elem.id) {
            groups.push(elem.id);
          } else {
            groups.push(elem);
          }
        }
      });

    let users = [];
      data?.users?.length &&
        data.users.forEach((elem) => {
          if (elem?.id) {
            users.push(elem.id);
          } else {
            users.push(elem);
          }
        });

      formData = {
        report: {
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
          forms: Array.isArray(data?.forms)
            ? data?.forms.map((item) => item.id || item)
            : data.forms?.id
            ? [data.forms?.id]
            : [],
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };

    setLoader(true);
    setErrors({});
    saveReport(formData)
      .then(() => {
        setLoader(false);
        setIsSaveModal(false);
        setRedirect(<Redirect to={`/analytics/reports-list`} />);
        dispatch(
          AlertState.actions.alert({
            text: "Report is saved",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        setLoader(false);
        if (response && response.data && response.data.errors) {
          const er = Object.keys(response.data.errors);
          let err = {};
          er.forEach((key) => {
            let keys = key.split(".");
            if (keys[0] === "report" && keys[1]) {
              err[keys[1]] =
                typeof response.data.errors[key] === "string"
                  ? response.data.errors[key]
                  : response.data.errors[key][0];
            } else {
              err[keys[0]] =
                typeof response.data.errors[key] === "string"
                  ? response.data.errors[key]
                  : response.data.errors[key][0];
            }
          });
          setErrors(err);
        }
      });
  };

  return (
    <>
      {redirect}
      <Loader visible={loaderSub || loaderUser || loader} />

      {isSaveModal && (
        <SaveModal
          setModal={setIsSaveModal}
          handleSave={handleSave}
          name={prevData.name}
          description={prevData.description}
          setData={(v) => {
            setData({ ...data, ...v });
          }}
          errors={errors}
        />
      )}

      <div className="create-report-page">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="top-buttons-wrapper">
              <div className="page-title">{title}</div>
              <div className="button-wrapper">
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={handleExport}
                  disabled={!isReportDetails || tableData.length === 0}
                >
                  Export
                </Button>
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={onOpenSaveModal}
                  disabled={!isReportDetails}
                >
                  Save
                </Button>
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={() => handleRun(false)}
                >
                  Run
                </Button>
              </div>
            </div>

            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">REPORT FILTERS</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className="body-wrapper">
                    <div
                    className="report-row"
                    >
                    <div className="report-row-in-row">
                        {report.map((elem) => {
                        if (elem.label === "Groups") {
                            return (
                            <div className="elem-container large">
                                <div className="elem-title">Group:</div>
                                <DropdownMultiselect
                                style={{ maxWidth: "200px" }}
                                label="Select group"
                                error={errors.groups}
                                options={
                                    groups.length
                                    ? groups.sort((a, b) => {
                                        const textA = a.name.toLowerCase();
                                        const textB = b.name.toLowerCase();
                                        return textA < textB
                                            ? -1
                                            : textA > textB
                                            ? 1
                                            : 0;
                                        })
                                    : []
                                }
                                setData={setData}
                                multiple={true}
                                name="groups"
                                data={data}
                                value={data.groups || []}
                                />
                            </div>
                            );
                        }
                        })}
                        {report.map((elem) => {
                        if (elem.label === "Users") {
                            return (
                            <div className={`elem-container large`}>
                                <div className="elem-title users">User:</div>
                                <DropdownMultiselect
                                style={{ width: "200px" }}
                                label="Select user"
                                error={errors.users}
                                setData={setData}
                                name="users"
                                options={
                                    users.length
                                    ? users.sort((a, b) => {
                                        const textA = a.name.toLowerCase();
                                        const textB = b.name.toLowerCase();
                                        return textA < textB
                                            ? -1
                                            : textA > textB
                                            ? 1
                                            : 0;
                                        })
                                    : []
                                }
                                data={data}
                                value={data.users || []}
                                />
                            </div>
                            );
                        }
                        })}
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="main-wrapper">
              {isReportDetails && (
                <Details
                  header={header}
                  data={tableData}
                  meta={tableMeta}
                  setSort={setSort}
                  sort={sort}
                  setMeta={(v) => setTableMeta({ ...tableMeta, ...v })}
                  role={role}
                  usersVis={usersVis}
                  groupsVis={groupsVis}
                  name={name}
                  additionalData={data}
                  isChartVisible={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  roles: state.auth.user.roles,
});

export default connect(mapState)(CreateReport);
